
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}

/* +++++++++++++++++++++++++++ */
/* �ǉ�css�@*/

.layer_board{ top:3%!important;}
.layer_board p.main_img{
	width:600px;
	margin:0 auto;
	display:block;
}
.layer_board .main_img img{
	width:100%;
}

.layer_board .btn_close a{ display:block; padding:20px 0;}

@media screen and (max-width: 1024px) {
.layer_board{ top:auto;}
.layer_board p.main_img{ width:90%;}
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}


/* +++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++ */

html,body {
	height: 100%;
}


* html .layer_board_bg,
* html .layer_board {
	position: absolute;
}

.layer_board_bg {
	position: fixed;
	width: 100%;
	height: 100%;
	
	z-index: 1000;
	
	top: 0;
	left: 0;
	
	display: none;
	cursor: pointer;
	background: #000;
}

.layer_board {
	display: none;
	position: fixed;
	margin: 50px 0px 0px 0px;
	
	text-align: center;
	z-index: 2000;
}